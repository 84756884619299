
import {defineComponent, onMounted, ref, watch} from 'vue'
import DocumentService from "@/core/services/DocumentService";
import store from "@/store";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "MedicalReviewPanel",
  components: {EntityLoading},
  props: {
    id: {type: String}
  },
  setup(props) {
    const data = ref({});
    const loading = ref(true);
    const loadData = async (id) => {
      loading.value = true;
      DocumentService.medicalReview(id).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    onMounted(() => {
      loadData(props.id)
      store.commit('SET_PANEL_LOADING', false)
    })
    watch(() => props.id, (cb) => {
      loadData(cb)
      store.commit('SET_PANEL_LOADING', false)
    })
    return {
      data,
      loading,
    }
  }
})
